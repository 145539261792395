import axios from 'axios';

import {
  getAuthUser,
  getPermissions,
  checkForAccess,
  createFeatureAccess,
  getSessionTime,
  checkForEnvMatch,
  getCookie
} from '@parkhub/parkhub-ui';

export {
  getAuthUser,
  getPermissions,
  checkForAccess,
  createFeatureAccess,
  getSessionTime,
  getCookie
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  withCredentials: true
});

/**
 * @param {string} [urlParams]
 */
export function signOut(urlParams = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + urlParams}`);
}

/**
 * Check if user is authenticated
 * @return {boolean} - if cookie is valid, env matches, and user has permissions true : false
 */
export const checkAuth = () => {
  const authCookie = getCookie('86553b39');
  const hasAppAccess = checkForAccess('suite.scheduler');

  return !!authCookie && checkForEnvMatch() && hasAppAccess;
};

/**
 * @returns {Promise} request updated user and permissions cookies
 */
export function refreshSession() {
  return new Promise((resolve, reject) => {
    const cookie = getCookie('86553b39');

    if (cookie) {
      resolve(axiosInstance.post('authenticate?refreshCookie=true'));

    } else {
      reject(`Can not refresh session because current cookie expired`);
    }
  });
}

export function redirectToSignIn() {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
}
