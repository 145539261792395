import { gql } from '@apollo/client';

export const EVENT_SERIES_QUERY = gql`
  query EventSeriesQuery(
    $eventId: String,
    $includePastEvents: Boolean
    ) {
    eventSeries(
      eventId: $eventId,
      includePastEvents: $includePastEvents
      ) {
      from
      id
      name
      parkingFrom
      parkingTo
      to
    }
  }
`;
