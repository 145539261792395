import PropTypes from 'prop-types';
import FilterGroupLayout from './FilterGroupLayout';
import { FilterGroupContextProvider, useFilterGroupContext } from './FilterGroupContext';
import './FilterGroup.scss';

const FilterGroup = ({
  label,
  filterConfig,
  className,
  style,
  onUpdate,
  onClear,
  popperOptions
}) => (
  <FilterGroupContextProvider
    popperOptions={popperOptions}
    filterConfig={filterConfig}
    onUpdate={onUpdate}
    onClear={onClear}
  >
    <FilterGroupLayout
      label={label}
      className={className}
      style={style}
    />
  </FilterGroupContextProvider>
);

FilterGroup.propTypes = {
  className: PropTypes.string, // class name for container element
  filterConfig: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string, // display label for the selected filter list,
    enableSearch: PropTypes.bool, // enable search for the selected group
    filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired, // array of filter options to display
    variableKey: PropTypes.string.isRequired // name of the key to set in the returned active filter object
  })).isRequired,
  label: PropTypes.string, // label for the filter group
  onClear: PropTypes.func, // callback function called after clearing filters
  onUpdate: PropTypes.func, // callback function that returns the updated active filter object
  popperOptions: PropTypes.any, // popper.js options
  style: PropTypes.object // style object for container element
};

FilterGroup.defaultProps = {
  className: null,
  label: 'Filters',
  popperOptions: {
    placement: 'bottom-end',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 6]
      }
    }]
  },
  style: null
};

export {
  FilterGroup,
  useFilterGroupContext
};
