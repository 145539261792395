import { gql } from '@apollo/client';

export const RESCHEDULE_EVENT_MUTATION = gql`
  mutation RescheduleEvent($rescheduledEvent: EventInput, $eventId: ID) {
    rescheduleEvent(rescheduledEvent: $rescheduledEvent, eventId: $eventId) {
      newEventId
      message
    }
  }
`;
