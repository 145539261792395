import { gql } from '@apollo/client';

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEventMutation($event: EventInput) {
    updateEvent(event: $event) {
      id
      name
    }
  }
`;
