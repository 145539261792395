import { gql } from '@apollo/client';

export const UPDATE_EVENT_SERIES_MUTATION = gql`
  mutation UpdateEventsMutation($eventIds: [String], $updates: EventInput) {
    updateEvents(eventIds: $eventIds, updates: $updates) {
      id
      name
      from
      to
      landmarkId
      parkingFrom
      parkingTo
      slug
    }
  }
`;
