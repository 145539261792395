import {
  format,
  startOfMinute,
  addMinutes,
  getMinutes,
  eachMinuteOfInterval,
  startOfDay,
  startOfWeek,
  startOfMonth,
  endOfDay,
  isSameDay,
  addMonths,
  subMonths,
  subWeeks,
  addWeeks,
  parseISO,
  setHours,
  setMinutes
} from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { uniqBy, findIndex } from 'lodash';

export function stripDateFromString(string) {
  const dateRegex = / - (\d{2}-\d{2}-\d{4})/;
  const containsDate = dateRegex.test(string);

  if (containsDate) {
    return string.replace(dateRegex, '');
  }

  return string;
}

// generate an array of time objects in 15 minute intervals
export function createTimesList(date) {
  const result = eachMinuteOfInterval({ start: startOfDay(date), end: endOfDay(date) }, { step: 15 });

  // unique to avoid duplicates due to DST
  return uniqBy(result.map((time) => ({
    label: format(time, 'h:mm a'),
    value: format(new Date(time), 'HH:mm')
  })), 'label');
}

// get the next available 15 minute interval after the current time
export function getOffset(date) {
  const start = startOfMinute(date);
  const diff = getMinutes(date) % 15;
  const isFifteen = diff === 0;
  const remainder = isFifteen ? 0 : 15 - diff;
  const offset = addMinutes(start, remainder);

  return offset;
};

// get available times for a given date
export function getTimes(startTime) {
  if (startTime) {
    const times = createTimesList(startTime);
    const offset = format(getOffset(startTime), 'h:mm a');
    const timeIndex = findIndex(createTimesList(startTime), o => o.label === offset);
    const list = times.slice(timeIndex);

    return list;
  }

  return createTimesList(startOfDay(new Date()));
};

/**
 * Combine a date and time string into a single date object
 * @param {date} dateObj - date object
 * @param {string} time - time string in HH:mm format
 * @return {date} date object
 */
export const combineDateTime = (dateObj, timeString) => {
  if (!dateObj || !timeString) {
    return null;
  }

  const time = timeString.split(':');
  const withHours = setHours(startOfDay(dateObj), time[0]);
  const dateTime = setMinutes(withHours, time[1]);

  return dateTime;
};

/**
 * Format 2 dates to a string
 * @param {date|number|string} startDate UTC or ISO date or string
 * @param {date|number|string} endDate UTC or ISO date or string
 * @param {string} timeZone IANA time zone
 * @return {string} formatted date string
 */
export const formatTimeRange = (startDate, endDate, timeZone) => {
  const sameDay = isSameDay(utcToZonedTime(startDate, timeZone), utcToZonedTime(endDate, timeZone));
  const timeFormat = sameDay ? 'h:mm a z' : 'M/dd/yyyy h:mm a z';

  return `${formatInTimeZone(parseISO(startDate), timeZone, 'M/dd/yyyy h:mm a z')} - ${formatInTimeZone(parseISO(endDate), timeZone, timeFormat)}`;
};

export const formatEventTime = (date, timeZone) => formatInTimeZone(parseISO(date), timeZone, 'M/dd/yy | h:mm a z');

export function getMonthAndYear(date) {
  return format(new Date(date), 'MMMM yyyy');
}

export function getMonthAndYearForStartOfWeek(date) {
  return getMonthAndYear(startOfWeek(new Date(date)));
}

export function getCurrentMonth() {
  return startOfMonth(new Date()).toISOString();
}

export function getNextMonth(date) {
  return startOfMonth(addMonths(new Date(date), 1)).toISOString();
}

export function getPrevMonth(date) {
  return startOfMonth(subMonths(new Date(date), 1)).toISOString();
}

export function getWeek(date) {
  const value = date || new Date();

  return startOfWeek(new Date(value)).toISOString();
}

export function getNextWeek(date) {
  return startOfWeek(addWeeks(new Date(date), 1)).toISOString();
}

export function getPrevWeek(date) {
  return startOfWeek(subWeeks(new Date(date), 1)).toISOString();
}
