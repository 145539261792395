import { gql } from '@apollo/client';

export const EVENT_OPTIONS_QUERY = gql`
  query EventOptionsQuery (
    $name: String
    $limit: Int
    $offset: Int
    $ids: [String]
    $live: Boolean
  ) {
    landmarks (
      name: $name
      limit: $limit
      offset: $offset
      ids: $ids
      live: $live
    ) {
      id
      name
      timezoneId
    }
    metaTags(depth: 2, type: event) {
      id: path
      name: displayName
    }
  }
`;

export const TAGS_QUERY = gql`
  query TagsQuery(
    $landmarkId: NullUUID
  ) {
    tags(
      landmarkId: $landmarkId,
      includeUnassociated: true
    ) {
      id
      name
    }
  }
`;
