import { gql } from '@apollo/client';

export const CREATE_EVENT_SERIES_MUTATION = gql`
  mutation CreateEventSeriesMutation($event: EventInput, $preview: Boolean, $rrule: RRuleInput) {
    createEventSeries(event: $event, preview: $preview, rrule: $rrule) {
      from
      id
      landmarkId
      name
      parkingFrom
      parkingTo
      slug
      to
    }
  }
`;
