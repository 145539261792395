import { gql } from '@apollo/client';

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEventMutation($event: EventInput) {
    createEvent(event: $event) {
      id
      name
    }
  }
`;
