import { DateTimePicker, useCreateEventContext } from '../';
import { combineDateTime } from '../../utils';
import { isAfter } from 'date-fns';
import cx from 'classnames';

const DateTimeRangePicker = ({
  id = 'range-picker',
  startDate,
  onFromDateChange,
  onFromTimeChange,
  onToDateChange,
  onToTimeChange,
  fromDate,
  fromTime,
  toDate,
  toTime,
  fromDateDisabled,
  fromTimeDisabled,
  toDateDisabled,
  toTimeDisabled,
  disabled,
  restrictedFrom,
  restrictedTo,
  multiline,
  errorOverride
}) => {
  const {
    timezone
  } = useCreateEventContext();

  const errorChecker = () => {
    if (fromDate && fromTime && toDate && toTime) {
      if (errorOverride) {
        return errorOverride;

      } else if (isAfter(combineDateTime(fromDate, fromTime), combineDateTime(toDate, toTime))) {
        return 'End time must be after start time';
      }
    }

    return null;
  };

  return (
    <div className={cx('date-time-range-picker-container', { 'date-time-range-picker-disabled': disabled })}>
      <div className={cx('date-time-range-picker', { multiline })}>
        <DateTimePicker
          id={`${id}-from`}
          selectedDate={fromDate}
          selectedTime={fromTime}
          onDateChange={onFromDateChange}
          onTimeChange={onFromTimeChange}
          startDate={startDate}
          dateDisabled={disabled || fromDateDisabled}
          timeDisabled={disabled || fromTimeDisabled}
          restrictToDateTime={restrictedFrom}
          label={multiline && 'From'}
          timezone={timezone}
        />
        {!multiline &&
          <div className="range-separator">
            &mdash;
          </div>
        }
        <DateTimePicker
          id={`${id}-to`}
          selectedDate={toDate}
          selectedTime={toTime}
          onDateChange={onToDateChange}
          onTimeChange={onToTimeChange}
          startDate={combineDateTime(fromDate, fromTime)}
          dateDisabled={disabled || toDateDisabled}
          timeDisabled={disabled || toTimeDisabled}
          restrictToDateTime={restrictedTo}
          label={multiline && 'To'}
          timezone={timezone}
        />
      </div>
      {errorChecker() &&
        <div className="date-time-range-picker-error">
          {errorChecker()}
        </div>
      }
    </div>
  );
};

export default DateTimeRangePicker;
