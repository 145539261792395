import cx from 'classnames';
import { Switch } from 'react-md';
import PropTypes from 'prop-types';

const SwitchInput = ({ id, className, disabled, control, setControl, onChange, ...rest }) => {
  return (
    <Switch
      id={id}
      name={id}
      disabled={disabled}
      className={cx({ active: control, 'read-only-switch': disabled }, className)}
      checked={control}
      onChange={e => {
        if (onChange) {
          onChange(e);
        }

        if (setControl) {
          setControl(e.currentTarget.checked);
        }
      }}
      {...rest}
    />
  );
};

SwitchInput.propTypes = {
  className: PropTypes.string,
  control: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setControl: PropTypes.func
};

export default SwitchInput;
