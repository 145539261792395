const CircleCheckIcon = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M17.2634 8.64594l-5.5 6.49996-.702.8297-.7685-.7685-3.00001-3 1.41422-1.4142 2.23149 2.2315 4.798-5.67034 1.5268 1.29188zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);

export default CircleCheckIcon;


