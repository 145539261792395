import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MessageQueue, Configuration, NestedDialogContextProvider } from 'react-md';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import { App, CreateEventContextProvider, LayoutContextProvider } from './components';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include'
  }),
  cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Configuration>
      <MessageQueue portal id="snackbar">
        <BrowserRouter>
          <LayoutContextProvider>
            <CreateEventContextProvider>
              <NestedDialogContextProvider>
                <App />
              </NestedDialogContextProvider>
            </CreateEventContextProvider>
          </LayoutContextProvider>
        </BrowserRouter>
      </MessageQueue>
    </Configuration>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
