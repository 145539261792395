import PropTypes from 'prop-types';
import { EventFontIcon, TimeToLeaveFontIcon, FontIcon } from 'react-md';
import { startOfDay, format, addMinutes, addHours } from 'date-fns';
import { useCreateEventContext, DateTimeRangePicker, SwitchInput } from '../';
import { combineDateTime } from '../../utils';

const RescheduleForm = ({ zonedTime }) => {
  const {
    rescheduledControl,
    setRescheduledControl,
    rescheduledFromDateControl,
    rescheduledFromTimeControl,
    rescheduledToDateControl,
    rescheduledToTimeControl,
    landmarkControl,
    setRescheduledFromDateControl,
    setRescheduledFromTimeControl,
    setRescheduledToDateControl,
    setRescheduledToTimeControl,
    rescheduledParkingFromDateControl,
    rescheduledParkingFromTimeControl,
    rescheduledParkingToDateControl,
    rescheduledParkingToTimeControl,
    setRescheduledParkingFromDateControl,
    setRescheduledParkingFromTimeControl,
    setRescheduledParkingToDateControl,
    setRescheduledParkingToTimeControl,
    rescheduledUnknownControl,
    setRescheduledUnknownControl,
    setFormChanged,
    selectedEvent,
    resetEventForm,
    clearRescheduledForm
  } = useCreateEventContext();

  const clearDates = () => {
    setRescheduledFromDateControl('');
    setRescheduledFromTimeControl('');
    setRescheduledToDateControl('');
    setRescheduledToTimeControl('');
    setRescheduledParkingFromDateControl('');
    setRescheduledParkingFromTimeControl('');
    setRescheduledParkingToDateControl('');
    setRescheduledParkingToTimeControl('');
  };

  const eventIsRescheduled = selectedEvent?.isRescheduled;
  const rescheduledWithEvent = selectedEvent?.rescheduleStatus === 'RESCHEDULED_WITH_EVENT';
  const rescheduledFrom = combineDateTime(rescheduledFromDateControl, rescheduledFromTimeControl);
  const rescheduledTo = combineDateTime(rescheduledToDateControl, rescheduledToTimeControl);
  const rescheduledParkingFrom = combineDateTime(rescheduledParkingFromDateControl, rescheduledParkingFromTimeControl);

  const hideDefaultParkingTimes = rescheduledUnknownControl || [
    rescheduledFromDateControl,
    rescheduledFromTimeControl,
    rescheduledToDateControl,
    rescheduledToTimeControl
  ].includes('');

  const setDefaultParkingTimes = () => {
    const defaultParkingFrom = addMinutes(addHours(startOfDay(rescheduledFrom), 3), 45);
    const defaultParkingTo = addMinutes(addHours(startOfDay(rescheduledTo), 23), 45);

    setRescheduledParkingFromDateControl(defaultParkingFrom);
    setRescheduledParkingFromTimeControl(format(defaultParkingFrom, 'HH:mm'));
    setRescheduledParkingToDateControl(defaultParkingTo);
    setRescheduledParkingToTimeControl(format(defaultParkingTo, 'HH:mm'));
  };

  return (
    <>
      {selectedEvent &&
        <>
          <hr className="separator" />
          <div className="form-field-container">
            <div className="form-field-icon">
              <FontIcon>event_repeat</FontIcon>
            </div>
            <label htmlFor="rescheduled" className="form-field-label">
              Rescheduled Event
            </label>
            <div className="form-field-input">
              <SwitchInput
                id="rescheduled"
                disabled={eventIsRescheduled}
                control={rescheduledControl}
                setControl={setRescheduledControl}
                onChange={(e) => {
                  const value = e.currentTarget.checked;

                  if (value) {
                    resetEventForm();
                  }
                  clearRescheduledForm();
                }}
              />
            </div>
            <div />
            <div className="form-field-label-description">
              all passes associated to the initial event will be re-associated to the newly scheduled event
            </div>
          </div>
        </>
      }
      {rescheduledControl &&
        <fieldset className="form-fieldset">
          <div className="form-field-label">Rescheduled Event Details</div>
          <div className="form-field-container date-time-form-field-container">
            <div className="form-field-icon time-range-icon">
              <EventFontIcon />
            </div>
            <div className="form-field">
              <div className="form-field-label">
                Start Event Date and Time
              </div>
              <div className="form-field-input">
                <DateTimeRangePicker
                  id="rescheduled-event-time"
                  multiline
                  restrictedFrom
                  restrictedTo
                  disabled={rescheduledWithEvent || rescheduledUnknownControl}
                  startDate={zonedTime}
                  fromDate={rescheduledFromDateControl}
                  fromTime={rescheduledFromTimeControl}
                  toDate={rescheduledToDateControl}
                  toTime={rescheduledToTimeControl}
                  fromDateDisabled={!landmarkControl?.id}
                  toDateDisabled={!landmarkControl?.id}
                  onFromDateChange={args => {
                    setRescheduledFromDateControl(args);
                    setFormChanged(true);
                  }}
                  onFromTimeChange={args => {
                    setRescheduledFromTimeControl(args);
                    setFormChanged(true);
                  }}
                  onToDateChange={args => {
                    setRescheduledToDateControl(args);
                    setFormChanged(true);
                  }}
                  onToTimeChange={args => {
                    setRescheduledToTimeControl(args);
                    setFormChanged(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-field-container date-time-form-field-container">
            <div className="form-field-icon time-range-icon">
              <TimeToLeaveFontIcon />
            </div>
            <div className="form-field">
              <div className="form-field-label">
                Parking Time
                {!hideDefaultParkingTimes &&
                  <span
                    className="form-field-label-hint"
                    onClick={setDefaultParkingTimes}
                  >
                    Set Default
                  </span>
                }
              </div>
              <div className="form-field-input">
                <DateTimeRangePicker
                  id="rescheduled-parking-time"
                  multiline
                  disabled={rescheduledWithEvent || rescheduledUnknownControl}
                  startDate={zonedTime}
                  fromDate={rescheduledParkingFromDateControl}
                  fromTime={rescheduledParkingFromTimeControl}
                  toDate={rescheduledParkingToDateControl}
                  toTime={rescheduledParkingToTimeControl}
                  fromDateDisabled={!landmarkControl?.id}
                  toDateDisabled={!landmarkControl?.id}
                  errorOverride={rescheduledParkingFrom > rescheduledFrom && 'Parking start time cannot be after event start time.'}
                  onFromDateChange={args => {
                    setRescheduledParkingFromDateControl(args);
                    setFormChanged(true);
                  }}
                  onFromTimeChange={args => {
                    setRescheduledParkingFromTimeControl(args);
                    setFormChanged(true);
                  }}
                  onToDateChange={args => {
                    setRescheduledParkingToDateControl(args);
                    setFormChanged(true);
                  }}
                  onToTimeChange={args => {
                    setRescheduledParkingToTimeControl(args);
                    setFormChanged(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-field-container no-icon">
            <label htmlFor="reschedule-unknown" className="form-field-label">
              Unknown date (to be determined)
            </label>
            <div className="form-field-input">
              <SwitchInput
                id="reschedule-unknown"
                disabled={rescheduledWithEvent}
                control={rescheduledUnknownControl}
                setControl={setRescheduledUnknownControl}
                onChange={() => {
                  clearDates();
                  setFormChanged(true);
                }}
              />
            </div>
          </div>
        </fieldset>
      }
    </>
  );
};

RescheduleForm.propTypes = {
  zonedTime: PropTypes.instanceOf(Date).isRequired
};

export default RescheduleForm;
