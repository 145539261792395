import { LanguageFontIcon } from 'react-md';
import { formatInTimeZone } from 'date-fns-tz';

const CurrentLandmarkTime = ({ timezone }) => {
  return (
    <div className="form-field-container">
      <div className="form-field-icon time-range-icon">
        <LanguageFontIcon />
      </div>
      <div className="form-field-label">
        Current Landmark Time
      </div>
      <div className="form-field-input">
        {timezone &&
          <time dateTime={formatInTimeZone(new Date(), timezone, 'MMM dd, yyyy h:mm a z')} className="time-label">
            {formatInTimeZone(new Date(), timezone, 'MMM dd, yyyy h:mm a z')}
          </time>
        }
      </div>
    </div>
  );
};

export default CurrentLandmarkTime;
