import SelectAsync from 'react-select/async';
import Select from 'react-select';
import { useQuery, useMutation } from '@apollo/client';
import { startOfDay, format, addMinutes, addHours } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { debounce } from 'lodash';
import { combineDateTime, orderByKey, createFilterList, isNotEmpty, stripDateFromString } from '../../utils';
import { useAddMessage, ErrorOutlineFontIcon, CheckCircleFontIcon } from 'react-md';
import { EVENT_OPTIONS_QUERY, TAGS_QUERY } from '../../graphql/eventOptions.query';
import { CREATE_EVENT_MUTATION } from '../../graphql/createEvent.mutation';
import { UPDATE_EVENT_MUTATION } from '../../graphql/updateEvent.mutation';
import { CREATE_EVENT_SERIES_MUTATION } from '../../graphql/createEventSeries.mutation';
import { UPDATE_EVENT_SERIES_MUTATION } from '../../graphql/updateEventSeries.mutation';
import { RESCHEDULE_EVENT_MUTATION } from '../../graphql/reschedule.mutation';
import {
  DateTimeRangePicker,
  useCreateEventContext,
  RecurringEventForm,
  RecurringPreviewDialog,
  SaveChoiceModal,
  RescheduleForm,
  SwitchInput,
  CurrentLandmarkTime
} from '../';
import {
  Button,
  Dialog,
  RoomFontIcon,
  EventFontIcon,
  TurnedInFontIcon,
  LabelFontIcon,
  CloseFontIcon,
  TimeToLeaveFontIcon,
  TrendingUpFontIcon,
  TextField
} from 'react-md';
import './CreateEventDialog.scss';

const CreateEventDialog = ({ visible, onHide, onSubmit }) => {
  const addMessage = useAddMessage();
  const {
    previewEventSeriesDialogVisible,
    setPreviewEventSeriesDialogVisible,
    saveChoiceModalVisible,
    setSaveChoiceModalVisible,
    setPreviewEvents,
    landmarkControl,
    setLandmarkControl,
    tagControl,
    setTagControl,
    goLiveControl,
    setGoLiveControl,
    transientControl,
    setTransientControl,
    eventNameControl,
    setEventNameControl,
    eventTypeControl,
    setEventTypeControl,
    timezone,
    setTimezone,
    eventFromDateControl,
    setEventFromDateControl,
    eventToDateControl,
    setEventToDateControl,
    eventFromTimeControl,
    setEventFromTimeControl,
    eventToTimeControl,
    setEventToTimeControl,
    parkingFromDateControl,
    setParkingFromDateControl,
    parkingToDateControl,
    setParkingToDateControl,
    parkingFromTimeControl,
    setParkingFromTimeControl,
    parkingToTimeControl,
    setParkingToTimeControl,
    clearForm,
    selectedEvent,
    formChanged,
    setFormChanged,
    recurringEventControl,
    countControl,
    intervalControl,
    byWeekDayControl,
    frequencyControl,
    untilControl,
    rescheduledControl,
    rescheduledFromDateControl,
    rescheduledFromTimeControl,
    rescheduledToDateControl,
    rescheduledToTimeControl,
    rescheduledParkingFromDateControl,
    rescheduledParkingFromTimeControl,
    rescheduledParkingToDateControl,
    rescheduledParkingToTimeControl,
    rescheduledUnknownControl
  } = useCreateEventContext();

  const handleHide = () => {
    onHide();
    clearForm();
  };

  const addErrorMessage = (message) => {
    addMessage({
      children: <><ErrorOutlineFontIcon style={{ color: '#ff4f4f' }} />&nbsp; {message}</>,
      action: 'dismiss'
    });
  };

  const addSuccessMessage = (message) => {
    addMessage({
      children: <><CheckCircleFontIcon style={{ color: '#4caf50' }} />&nbsp; {message}</>,
      action: 'dismiss'
    });
  };

  const checkForCommonError = (errorMessage, defaultMessage = 'Something went wrong') => {
    if (errorMessage?.message?.toLowerCase()?.includes('cannot pick a to/from time in the past')) {
      addErrorMessage('Cannot pick a From/To time in the past');

    } else if (errorMessage?.message?.toLowerCase()?.includes('parkingfrom cannot be after from')) {
      addErrorMessage('Parking time cannot begin after event start time');

    } else if (errorMessage?.message?.toLowerCase()?.includes('parkingto cannot be before to')) {
      addErrorMessage('Parking time cannot end before event end time');

    } else if (errorMessage?.message?.toLowerCase()?.includes('event is legal transient')) {
      addErrorMessage('You already have event an scheduled for the same time. Unable to create overlapping event');

    } else if (errorMessage?.message?.toLowerCase()?.includes('duplicate event')) {
      addErrorMessage('You already have this event scheduled. Unable to create duplicate event');

    } else {
      addErrorMessage(defaultMessage);
    }
  };

  const {
    loading: eventOptionsLoading,
    data: eventOptionsData,
    refetch: refetchEventOptions
  } = useQuery(EVENT_OPTIONS_QUERY, {
    skip: !visible,
    variables: {
      limit: 10000
    },
    onError: (err) => {
      console.error(err);
      addErrorMessage('Error fetching options');
    }
  });

  const { loading: tagsLoading, data: tagsData } = useQuery(TAGS_QUERY, {
    skip: !landmarkControl?.id,
    variables: {
      landmarkId: landmarkControl?.id
    },
    onError: (err) => {
      console.error(err);
      addErrorMessage('Error fetching options');
    }
  });

  const [createEvent, { loading: createEventLoading, reset: createEventReset }] = useMutation(CREATE_EVENT_MUTATION, {
    onError: (err) => {
      console.error(err);
      checkForCommonError(err, 'Error creating event');
      createEventReset();
    },
    onCompleted: (response) => {
      onSubmit();
      addSuccessMessage(`${response?.createEvent?.name} created`);
      createEventReset();
      handleHide();
    }
  });

  const [updateEvent, { loading: updateEventLoading, reset: updateEventReset }] = useMutation(UPDATE_EVENT_MUTATION, {
    onError: (err) => {
      console.error(err);
      checkForCommonError(err, 'Error updating event');
      updateEventReset();
    },
    onCompleted: (response) => {
      onSubmit();
      addSuccessMessage(`${response?.updateEvent?.name} updated`);
      updateEventReset();
      handleHide();
    }
  });

  const [createEventSeries, { loading: createEventSeriesLoading, reset: createEventSeriesReset }] = useMutation(CREATE_EVENT_SERIES_MUTATION, {
    onError: (err) => {
      console.error(err);

      if (err?.message?.toLowerCase()?.includes('last event date past limit')) {
        addErrorMessage('Last event date is past farthest available date');

      } else if (err?.message?.toLowerCase()?.includes(`either 'count' or 'until' rule field is required`)) {
        addErrorMessage(`Ends 'On' or 'After' is required`);

      } else if (err?.message?.toLowerCase()?.includes('no frequency found')) {
        addErrorMessage(`Repeat frequency is required`);

      } else if (err?.message?.toLowerCase()?.includes('recurring rule produces no dates - update parameters')) {
        addErrorMessage(`Recurring rule produces no dates. Please check parameters and update.`);

      } else {
        checkForCommonError(err, 'Error creating event series');
      }

      createEventSeriesReset();
    }
  });

  const [updateEventSeries, { loading: updateEventSeriesLoading, reset: updateEventSeriesReset }] = useMutation(UPDATE_EVENT_SERIES_MUTATION, {
    onError: (err) => {
      console.error(err);
      checkForCommonError(err, 'Error updating event series');
      updateEventSeriesReset();
    }
  });

  const [rescheduleEvent, { loading: rescheduleEventLoading, reset: rescheduleEventReset }] = useMutation(RESCHEDULE_EVENT_MUTATION, {
    onError: (err) => {
      console.error(err);
      checkForCommonError(err, 'Error rescheduling event');
      rescheduleEventReset();
    },
    onCompleted: (response) => {
      onSubmit();
      addSuccessMessage(`Event rescheduled`);
      rescheduleEventReset();
      handleHide();
    }
  });

  const handleLandmarkChange = (option) => {
    setLandmarkControl(option);
    setTimezone(option.timezoneId);
    setTagControl('');
  };

  const eventFrom = combineDateTime(eventFromDateControl, eventFromTimeControl);
  const eventTo = combineDateTime(eventToDateControl, eventToTimeControl);
  const parkingFrom = combineDateTime(parkingFromDateControl, parkingFromTimeControl);
  const parkingTo = combineDateTime(parkingToDateControl, parkingToTimeControl);
  const rescheduledFrom = combineDateTime(rescheduledFromDateControl, rescheduledFromTimeControl);
  const rescheduledTo = combineDateTime(rescheduledToDateControl, rescheduledToTimeControl);
  const rescheduledParkingFrom = combineDateTime(rescheduledParkingFromDateControl, rescheduledParkingFromTimeControl);
  const rescheduledParkingTo = combineDateTime(rescheduledParkingToDateControl, rescheduledParkingToTimeControl);

  const eventVariables = {
    name: eventNameControl || null,
    landmarkId: landmarkControl?.id || null,
    tagId: tagControl?.id || null,
    tagName: tagControl?.name || null,
    metatagPath: eventTypeControl?.id || null,
    from: timezone && eventFrom ? zonedTimeToUtc(eventFrom, timezone).toISOString() : null,
    to: timezone && eventTo ? zonedTimeToUtc(eventTo, timezone).toISOString() : null,
    parkingFrom: timezone && parkingFrom ? zonedTimeToUtc(parkingFrom, timezone).toISOString() : null,
    parkingTo: timezone && parkingTo ? zonedTimeToUtc(parkingTo, timezone).toISOString() : null,
    live: goLiveControl,
    transient: transientControl
  };

  const eventSeriesVariables = {
    interval: parseInt(intervalControl, 10),
    frequency: frequencyControl.value,
    byWeekDay: byWeekDayControl,
    until: untilControl ? zonedTimeToUtc(untilControl, timezone).toISOString() : null,
    count: parseInt(countControl, 10) || null
  };

  const updateSingleEvent = () => {
    updateEvent({
      variables: {
        event: {
          ...eventVariables,
          id: selectedEvent?.id
        }
      }
    });
  };

  const handleSubmit = () => {
    if (createEventLoading || createEventSeriesLoading || updateEventLoading || updateEventSeriesLoading || rescheduleEventLoading) {
      return;
    }

    if (rescheduledControl) {
      // Rescheduled event
      rescheduleEvent({
        variables: {
          eventId: selectedEvent?.id,
          ...!rescheduledUnknownControl && {
            rescheduledEvent: {
              ...eventVariables,
              from: rescheduledFrom?.toISOString(),
              to: rescheduledTo?.toISOString(),
              parkingFrom: rescheduledParkingFrom?.toISOString(),
              parkingTo: rescheduledParkingTo?.toISOString()
            }
          }
        }
      });

    } else if (selectedEvent) {
      // Update Event Series
      if (selectedEvent.isRecurring) {
        setSaveChoiceModalVisible(true);

      } else {
        // Update Single Event
        updateSingleEvent();
      }
    } else {
      // Create New Event Series
      if (recurringEventControl.value === 'custom') {
        createEventSeries({
          variables: {
            event: eventVariables,
            rrule: eventSeriesVariables,
            preview: true
          },
          onCompleted: (response) => {
            setPreviewEvents(response?.createEventSeries);
            setPreviewEventSeriesDialogVisible(true);
          }
        });

      } else {
        // Create New Single Event
        createEvent({
          variables: {
            event: eventVariables
          }
        });
      }
    }
  };

  const isDisabled = () => {
    const { frequency, until, count, interval } = eventSeriesVariables;
    const allControls = Object.values(eventVariables);
    const hasNullValues = allControls.includes(null);
    const eventTimesNonSequential = eventFrom && eventTo && (eventFrom > eventTo);
    const parkingTimesNonSequential = parkingFrom && parkingTo && (parkingFrom > parkingTo);
    const recurringEventIsCustom = recurringEventControl.value === 'custom';
    const recurringFormValues = [!frequency, ((!until && !count) || (!count && !until)), !interval];
    const hasRecurringFormErrors = recurringEventIsCustom ? recurringFormValues.some(v => v) : false;
    const rescheduledTimesNonSequential = rescheduledFrom && rescheduledTo && (rescheduledFrom > rescheduledTo);
    const rescheduledParkingTimesNonSequential = rescheduledParkingFrom && rescheduledParkingTo && (rescheduledParkingFrom > rescheduledParkingTo);
    const rescheduledTimesEmpty = [rescheduledFrom, rescheduledTo, rescheduledParkingFrom, rescheduledParkingTo].includes(null);
    const rescheduledTimesInvalid = rescheduledTimesNonSequential || rescheduledParkingTimesNonSequential || rescheduledTimesEmpty;
    const hasRescheduledFormErrors = rescheduledControl ? rescheduledUnknownControl ? false : rescheduledTimesInvalid : false;

    return [
      createEventLoading,
      updateEventLoading,
      createEventSeriesLoading,
      hasNullValues,
      eventTimesNonSequential,
      parkingTimesNonSequential,
      hasRecurringFormErrors,
      hasRescheduledFormErrors,
      !formChanged
    ].includes(true);
  };

  const getZonedTime = () => timezone ? utcToZonedTime(new Date(), timezone) : new Date();

  const setDefaultParkingTimes = () => {
    const defaultParkingFrom = addMinutes(addHours(startOfDay(eventFrom), 3), 45);
    const defaultParkingTo = addMinutes(addHours(startOfDay(eventTo), 23), 45);

    setParkingFromDateControl(defaultParkingFrom);
    setParkingFromTimeControl(format(defaultParkingFrom, 'HH:mm'));
    setParkingToDateControl(defaultParkingTo);
    setParkingToTimeControl(format(defaultParkingTo, 'HH:mm'));
  };

  const setDefaultEventToDateAndTime = (time) => {
    if (!eventToDateControl) {
      const defaultEventTo = addHours(combineDateTime(startOfDay(eventFromDateControl), time), 3);
      setEventToDateControl(defaultEventTo);
      setEventToTimeControl(format(defaultEventTo, 'HH:mm'));
    }
  };

  const hideDefaultParkingTimes = (rescheduledControl) || [
    eventFromDateControl,
    eventFromTimeControl,
    eventToDateControl,
    eventToTimeControl
  ].includes('');

  const getSubmitLabel = () => {
    if (rescheduledControl) {
      return 'RESCHEDULE EVENT';
    } else if (selectedEvent) {
      return 'SAVE CHANGES';
    } else {
      return 'CREATE EVENT';
    }
  };

  const loadOptions = debounce((inputValue, callback) => {
    refetchEventOptions({ name: inputValue }).then((result) => {
      callback(result?.data?.landmarks);
    });
  }, 300);

  return (
    <>
      <Dialog
        id="create-event-dialog"
        className="create-event-dialog"
        visible={visible}
        onRequestClose={handleHide}
        aria-describedby="create-event"
        role="alertdialog"
        modal
      >
        <div className="create-event-dialog-content">
          <Button
            buttonType="icon"
            className="close-button"
            onClick={handleHide}
          >
            <CloseFontIcon />
          </Button>
          <form className="event-form">
            <div className="event-name-container">
              <TextField
                id="event-name-input"
                className="event-name-input"
                placeholder="Event Name"
                theme="underline"
                leftChildren={<TurnedInFontIcon />}
                onChange={e => {
                  setEventNameControl(e.target.value);
                  setFormChanged(true);
                }}
                value={eventNameControl}
              />
              {selectedEvent?.isRecurring && eventFromDateControl &&
                <time title="date will be appended to event name" className="event-name-date" dateTime={new Date(eventFromDateControl)}>
                  {format(new Date(eventFromDateControl), ' - MM-dd-yyyy')}
                </time>
              }
            </div>
            <div className="form-field-container">
              <div className="form-field-icon">
                <RoomFontIcon />
              </div>
              <label htmlFor="landmark" className="form-field-label">
                Landmark
              </label>
              <div className="form-field-input">
                <SelectAsync
                  id="landmark"
                  loadOptions={loadOptions}
                  name="landmark"
                  isDisabled={rescheduledControl}
                  isLoading={eventOptionsLoading}
                  className="form-field-dropdown"
                  classNamePrefix="select"
                  defaultOptions={eventOptionsData?.landmarks}
                  value={landmarkControl}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    handleLandmarkChange(value);
                    setFormChanged(true);
                  }}
                />
              </div>
            </div>
            <div className="form-field-container">
              <div className="form-field-icon">
                <TurnedInFontIcon />
              </div>
              <label htmlFor="tag-pricing" className="form-field-label">
                Tag Pricing
              </label>
              <div className="form-field-input">
                <Select
                  isDisabled={rescheduledControl || !landmarkControl?.id}
                  id="tag-pricing"
                  name="tag-pricing"
                  isLoading={tagsLoading}
                  className="form-field-dropdown"
                  classNamePrefix="select"
                  options={orderByKey(tagsData?.tags, 'name')}
                  value={tagControl}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setTagControl(value);
                    setFormChanged(true);
                  }}
                />
              </div>
            </div>
            <div className="form-field-container">
              <div className="form-field-icon">
                <LabelFontIcon />
              </div>
              <label htmlFor="event-type" className="form-field-label">
                Event Type
              </label>
              <div className="form-field-input">
                <Select
                  id="event-type"
                  name="event-type"
                  isDisabled={rescheduledControl}
                  isLoading={eventOptionsLoading}
                  className="form-field-dropdown"
                  classNamePrefix="select"
                  options={eventOptionsData?.metaTags}
                  value={eventTypeControl}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setEventTypeControl(value);
                    setFormChanged(true);
                  }}
                />
              </div>
            </div>
            <CurrentLandmarkTime timezone={timezone} />
            <div className="form-field-container date-time-form-field-container">
              <div className="form-field-icon time-range-icon">
                <EventFontIcon />
              </div>
              <div className="form-field">
                <div className="form-field-label">
                  Start Event Date and Time
                </div>
                <div className="form-field-input">
                  <DateTimeRangePicker
                    id="event-time"
                    restrictedFrom
                    restrictedTo
                    startDate={getZonedTime()}
                    fromDate={eventFromDateControl}
                    fromTime={eventFromTimeControl}
                    toDate={eventToDateControl}
                    toTime={eventToTimeControl}
                    disabled={rescheduledControl}
                    fromDateDisabled={!landmarkControl?.id}
                    toDateDisabled={!landmarkControl?.id}
                    onFromDateChange={args => {
                      setEventFromDateControl(args);
                      setFormChanged(true);
                    }}
                    onFromTimeChange={args => {
                      setEventFromTimeControl(args);
                      setFormChanged(true);

                      if (eventFromDateControl && args) {
                        setDefaultEventToDateAndTime(args);
                      }
                    }}
                    onToDateChange={args => {
                      setEventToDateControl(args);
                      setFormChanged(true);
                    }}
                    onToTimeChange={args => {
                      setEventToTimeControl(args);
                      setFormChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-field-container date-time-form-field-container">
              <div className="form-field-icon time-range-icon">
                <TimeToLeaveFontIcon />
              </div>
              <div className="form-field">
                <div className="form-field-label">
                  Parking Time
                  {!hideDefaultParkingTimes &&
                    <span
                      className="form-field-label-hint"
                      onClick={setDefaultParkingTimes}
                    >
                      Set Default
                    </span>
                  }
                </div>
                <div className="form-field-input">
                  <DateTimeRangePicker
                    id="parking-time"
                    startDate={getZonedTime()}
                    fromDate={parkingFromDateControl}
                    fromTime={parkingFromTimeControl}
                    toDate={parkingToDateControl}
                    toTime={parkingToTimeControl}
                    disabled={rescheduledControl}
                    fromDateDisabled={!landmarkControl?.id}
                    toDateDisabled={!landmarkControl?.id}
                    errorOverride={parkingFrom > eventFrom && 'Parking start time cannot be after event start time.'}
                    onFromDateChange={args => {
                      setParkingFromDateControl(args);
                      setFormChanged(true);
                    }}
                    onFromTimeChange={args => {
                      setParkingFromTimeControl(args);
                      setFormChanged(true);
                    }}
                    onToDateChange={args => {
                      setParkingToDateControl(args);
                      setFormChanged(true);
                    }}
                    onToTimeChange={args => {
                      setParkingToTimeControl(args);
                      setFormChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <RecurringEventForm isLoading={eventOptionsLoading} />
            <div className="form-field-container ">
              <div className="form-field-icon">
                <TrendingUpFontIcon />
              </div>
              <label htmlFor="go-live" className="form-field-label">
                Go Live
              </label>
              <div className="form-field-input">
                <SwitchInput
                  id="go-live"
                  control={goLiveControl}
                  setControl={setGoLiveControl}
                  onChange={() => setFormChanged(true)}
                />
              </div>
            </div>
            <div className="form-field-container">
              <div className="form-field-icon">
                <TimeToLeaveFontIcon />
              </div>
              <label htmlFor="transient" className="form-field-label">
                Transient
              </label>
              <div className="form-field-input">
                <SwitchInput
                  id="transient"
                  control={transientControl}
                  setControl={setTransientControl}
                  onChange={() => setFormChanged(true)}
                />
              </div>
            </div>
            <RescheduleForm zonedTime={getZonedTime()} />
          </form>
        </div>
        <div className="create-event-dialog-actions">
          <Button
            theme="clear"
            themeType="contained"
            className="tertiary"
            onClick={handleHide}
          >
            Cancel
          </Button>
          <Button
            theme="primary"
            themeType="contained"
            className="full"
            disabled={isDisabled()}
            onClick={handleSubmit}
          >
            {getSubmitLabel()}
          </Button>
        </div>
      </Dialog>
      <SaveChoiceModal
        visible={saveChoiceModalVisible}
        onClose={() => setSaveChoiceModalVisible(false)}
        onSubmitSingle={updateSingleEvent}
        onSubmitMultiple={() => setPreviewEventSeriesDialogVisible(true)}
      />
      <RecurringPreviewDialog
        eventSeriesControls={isNotEmpty(selectedEvent?.eventSeries) && createFilterList(selectedEvent?.eventSeries, 'from')}
        visible={previewEventSeriesDialogVisible}
        onClose={() => setPreviewEventSeriesDialogVisible(false)}
        onSubmitUpdate={
          (ids) => {
            const variableUpdates = {
              ...eventVariables,
              name: stripDateFromString(eventNameControl)
            };

            updateEventSeries({
              variables: {
                eventIds: ids,
                updates: variableUpdates
              },
              onCompleted: (response) => {
                onSubmit();
                handleHide();
                addSuccessMessage(`${response?.updateEvents?.length} events updated`);
              }
            });
          }
        }
        onSubmitCreate={() => createEventSeries({
          variables: {
            event: eventVariables,
            rrule: eventSeriesVariables
          },
          onCompleted: (response) => {
            onSubmit();
            handleHide();
            addSuccessMessage(`${response?.createEventSeries?.length} events created`);
          }
        })}
      />
    </>
  );
};

export default CreateEventDialog;
