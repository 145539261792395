import ParkhubLogo from '../../assets/parkhub-scheduler-logo-gray.svg';
import { useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SideMenu, EventDialog } from '../../components';
import * as routes from '../../constants/routes';
import { Header } from '@parkhub/parkhub-ui';
import { signOut, getAuthUser, createFeatureAccess } from '../../utils';
import './Layout.scss';

const NoMatch = lazy(() => import('../NoMatch/NoMatch'));
const Week = lazy(() => import('../Week/Week'));
const Agenda = lazy(() => import('../Agenda/Agenda'));

const Layout = () => {
  const access = createFeatureAccess();
  const location = useLocation();
  const navigate = useNavigate();
  const authUser = getAuthUser();

  useEffect(() => {
    if (location.pathname === routes.ROOT) {
      navigate(routes.WEEK);
    }
  });

  return (
    <>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={213}
        appMenuActive
        logoClick={() => navigate(routes.WEEK)}
      />
      <main id="layout">
        <SideMenu activeRoute={location.pathname} />
        <div className="content-container">
          <Routes>
            <Route
              path={routes.WEEK}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Week />
                </Suspense>
              }
            />
            <Route
              path={routes.AGENDA}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Agenda />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <NoMatch />
                </Suspense>
              }
            />
          </Routes>
          <EventDialog />
        </div>
      </main>
    </>
  );
};

export default Layout;
