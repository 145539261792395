import { createContext, useContext, useState } from 'react';

const LayoutContext = createContext([{}, () => { }]);

export const LayoutContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState('');
  const [eventDialogVisible, setEventDialogVisible] = useState(false);
  const [selectedEventTile, setSelectedEventTile] = useState(null);

  return (
    <LayoutContext.Provider
      value={{
        isLoading,
        setIsLoading,
        eventDialogVisible,
        setEventDialogVisible,
        selectedEventTile,
        setSelectedEventTile
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
