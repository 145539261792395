import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';
import { Radio, WarningFontIcon, Tooltip, useTooltip, RefreshFontIcon } from 'react-md';
import Select from 'react-select';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import cx from 'classnames';
import { useCreateEventContext } from '../';
import './RecurringEventForm.scss';

const RECURRING_EVENT_DAY_LIMIT = Number(process.env.REACT_APP_RECURRING_EVENT_DAY_LIMIT) || 183;

const frequencyOptions = [
  {
    label: 'days',
    value: 'DAILY'
  },
  {
    label: 'weeks',
    value: 'WEEKLY'
  },
  {
    label: 'months',
    value: 'MONTHLY'
  }
];

const RecurringEventForm = ({ isLoading }) => {
  const { elementProps, tooltipProps } = useTooltip({
    baseId: 'warning-tooltip',
    defaultPosition: 'below'
  });

  const {
    countControl,
    setCountControl,
    frequencyControl,
    setFrequencyControl,
    untilControl,
    setUntilControl,
    intervalControl,
    setIntervalControl,
    byWeekDayControl,
    setByWeekDayControl,
    recurringEventControl,
    selectedEvent,
    landmarkControl,
    recurringEventOptions,
    setRecurringEventControl,
    clearRecurringForm
  } = useCreateEventContext();

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [ends, setEnds] = useState(null);
  const popperRef = useRef(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'right'
  });

  const handleClick = (e) => {
    if (popperRef?.current?.contains(e.target)) {
      return;
    }

    setIsPopperOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const handleRecurringEventChange = (option) => {
    clearRecurringForm();
    setRecurringEventControl(option);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
  };

  const handleDaySelect = (value) => {
    setUntilControl(value);
    setIsPopperOpen(false);
  };

  const handleByWeekDaySelect = (value) => {
    const exists = byWeekDayControl.includes(value);

    if (exists) {
      setByWeekDayControl(byWeekDayControl.filter(item => item !== value));
    }
    else {
      setByWeekDayControl([...byWeekDayControl, value]);
    }
  };

  const handleEndsChange = (value) => {
    if (value === 'on') {
      setCountControl('');
    }

    if (value === 'after') {
      setUntilControl('');
    }

    setEnds(value);
  };

  const handleNumberKeyDown = (e) => {
    if (e.keyCode === 189) {
      e.preventDefault();
    }
  };

  const handleFrequencyChange = (value) => {
    setByWeekDayControl([]);
    setFrequencyControl(value);
  };

  const endOnDatePickerDisabled = ends !== 'on';
  const maxDate = addDays(new Date(), RECURRING_EVENT_DAY_LIMIT);

  return (
    <>
      {!selectedEvent &&
        <div className="form-field-container">
          <div className="form-field-icon">
            <RefreshFontIcon />
          </div>
          <label htmlFor="recurring" className="form-field-label">
            Recurring Event
          </label>
          <div className="form-field-input">
            <Select
              id="recurring"
              name="recurring"
              isDisabled={!landmarkControl?.id}
              isLoading={isLoading}
              className="form-field-dropdown"
              classNamePrefix="select"
              options={recurringEventOptions}
              value={recurringEventControl}
              onChange={handleRecurringEventChange}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2
                })
              }}
            />
          </div>
        </div>
      }
      {recurringEventControl.value === 'custom' &&
        <div className="recurring-event-form">
          <div className="form-section">
            Farthest available date:
            <time className="farthest-date">
              {format(maxDate, 'EEE LLL d, yyyy')}
            </time>
          </div>
          <div className="form-section">
            <div className="form-section-child form-section-label">
              Repeat every
            </div>
            <div className="form-section-child">
              <input
                id="interval"
                name="interval"
                type="number"
                className="recurring-input interval-input"
                value={intervalControl}
                onChange={(e) => setIntervalControl(e.target.value)}
                onKeyDown={handleNumberKeyDown}
                min="1"
                max="999"
                required={!endOnDatePickerDisabled}
              />
            </div>
            <div className="form-section-child">
              <Select
                id="frequency"
                name="frequency"
                className="form-field-dropdown"
                classNamePrefix="select"
                options={frequencyOptions}
                onChange={(option) => handleFrequencyChange(option)}
                value={frequencyControl}
                required
              />
            </div>
          </div>
          {frequencyControl.value === 'WEEKLY' &&
            <div className="form-section">
              <div className="form-section-label">
                Repeat on
              </div>
              <div className="button-group">
                <div
                  id="sunday"
                  className={cx('button-child', { active: byWeekDayControl.includes(6) })}
                  onClick={() => handleByWeekDaySelect(6)}
                >
                  S
                </div>
                <div
                  id="monday"
                  className={cx('button-child', { active: byWeekDayControl.includes(0) })}
                  onClick={() => handleByWeekDaySelect(0)}
                >
                  M
                </div>
                <div
                  id="tuesday"
                  className={cx('button-child', { active: byWeekDayControl.includes(1) })}
                  onClick={() => handleByWeekDaySelect(1)}
                >
                  T
                </div>
                <div
                  id="wednesday"
                  className={cx('button-child', { active: byWeekDayControl.includes(2) })}
                  onClick={() => handleByWeekDaySelect(2)}
                >
                  W
                </div>
                <div
                  id="thursday"
                  className={cx('button-child', { active: byWeekDayControl.includes(3) })}
                  onClick={() => handleByWeekDaySelect(3)}
                >
                  T
                </div>
                <div
                  id="friday"
                  className={cx('button-child', { active: byWeekDayControl.includes(4) })}
                  onClick={() => handleByWeekDaySelect(4)}
                >
                  F
                </div>
                <div
                  id="saturday"
                  className={cx('button-child', { active: byWeekDayControl.includes(5) })}
                  onClick={() => handleByWeekDaySelect(5)}
                >
                  S
                </div>
              </div>
            </div>
          }
          <div className="form-section end-section">
            <div className="form-section-child form-section-label">
              Ends
            </div>
            <div className="form-section-child">
              <Radio
                className="ph-radio"
                id="ends-1"
                name="ends"
                value="on"
                label="On"
                onChange={(e) => handleEndsChange(e.target.value)}
                checked={ends === 'on'}
                required
              />
              <Radio
                className="ph-radio"
                id="ends-2"
                name="ends"
                value="after"
                label="After"
                onChange={(e) => handleEndsChange(e.target.value)}
                checked={ends === 'after'}
                required
              />
            </div>
            <div className="form-section-child">
              <div className={cx('date-time-picker', { 'date-time-picker-disabled': endOnDatePickerDisabled })}>
                <div ref={popperRef}>
                  <input
                    id="until-date-input"
                    name="until-date-input"
                    type="text"
                    placeholder="Date"
                    value={untilControl ? format(untilControl, 'LLL d, yyyy') : ''}
                    onChange={handleInputChange}
                    className="date-time-picker-input"
                    onClick={() => endOnDatePickerDisabled ? null : setIsPopperOpen(true)}
                    disabled={endOnDatePickerDisabled}
                    required={!endOnDatePickerDisabled}
                  />
                  {isPopperOpen && (
                    <div
                      {...popper.attributes.popper}
                      tabIndex={-1}
                      style={popper.styles.popper}
                      className="popper-container"
                      ref={setPopperElement}
                      role="dialog"
                    >
                      <DayPicker
                        id="ends-on-date-picker"
                        fromDate={untilControl || new Date()}
                        toDate={maxDate}
                        initialFocus={isPopperOpen}
                        mode="single"
                        defaultMonth={new Date()}
                        selected={untilControl ? new Date(untilControl) : ''}
                        onSelect={handleDaySelect}
                      />
                    </div>
                  )}
                </div>
              </div>
              <br />
              <div className="after-section">
                <input
                  id="count"
                  name="count"
                  type="number"
                  className="recurring-input count-input"
                  value={countControl}
                  onKeyDown={handleNumberKeyDown}
                  onChange={(e) => setCountControl(e.target.value)}
                  disabled={ends !== 'after'}
                  required={ends === 'after'}
                  min="1"
                  max="999"
                />
                occurrences
                <>
                  <WarningFontIcon className="warning-icon" {...elementProps} />
                  <Tooltip {...tooltipProps}>Events may only be scheduled prior to your organization's contract expiration date</Tooltip>
                </>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

RecurringEventForm.propTypes = {
  isLoading: PropTypes.bool
};

export default RecurringEventForm;
