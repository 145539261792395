import { SearchIcon } from '../../assets/react-icons/';
import _ from 'lodash';
import './SearchInput.scss';

const SearchInput = ({ update }) => {

  const debouncedSearch = _.debounce((input) => update({ 'keyword': input }), 1000);

  function handleChange(val) {
    debouncedSearch(val);
  };

  return (
    <div className="search-input-container" >
      <input type="text" onChange={(e) => handleChange(e.target.value)} placeholder="Search by keyword" />
      <span>
        <SearchIcon color="rgba(0,0,0,.6)" width={24} />
      </span>
    </div>
  );
};

export default SearchInput;
