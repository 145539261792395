import { Button } from '@parkhub/parkhub-ui';
import { FilterGroup, SearchInput, CreateEventDialog, useCreateEventContext } from '../';
import { isNotEmpty, getStatusIndicatorStyle } from '../../utils';
import './TopActionBar.scss';

const TopActionBar = ({ updateVariables, dateControls, filterOptions, refetch }) => {
  const { createEventDialogVisible, setCreateEventDialogVisible } = useCreateEventContext();

  const prepaidStatusFormatter = (value) => {
    return {
      PREPAIDS_ASSOCIATED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Associated</span></>,
      EXPECTED_NOT_RECEIVED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Expecting</span></>,
      RECEIVED_NOT_ASSOCIATED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Received</span></>,
      NO_PREPAIDS_EXPECTED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Not Expecting</span></>,
      UNKNOWN: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Unknown</span></>
    }[value];
  };

  const eventStatusFormatter = (value) => {
    return {
      LIVE: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Live</span></>,
      NOT_LIVE: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Not Live</span></>
    }[value];
  };

  return (
    <div className="top-action-bar">
      <div className="date-controls">
        {dateControls}
      </div>
      <div className="action-container">
        {isNotEmpty(filterOptions) &&
          <>
            <SearchInput
              variableKey="keyword"
              update={updateVariables}
            />
            <FilterGroup
              onUpdate={updateVariables}
              filterConfig={[
                {
                  variableKey: 'statuses',
                  label: 'Prepaid Status',
                  filterOptions: filterOptions?.status,
                  formatter: prepaidStatusFormatter,
                  enableSearch: false
                },
                {
                  variableKey: 'eventStatus',
                  label: 'Event Status',
                  filterOptions: filterOptions?.eventStatus,
                  formatter: eventStatusFormatter,
                  enableSearch: false
                },
                {
                  variableKey: 'locations',
                  label: 'Location',
                  filterOptions: filterOptions?.location,
                  enableSearch: true
                },
                {
                  variableKey: 'regions',
                  label: 'Region',
                  filterOptions: filterOptions?.region,
                  enableSearch: true
                },
                {
                  variableKey: 'eventTypes',
                  label: 'Type',
                  filterOptions: filterOptions?.eventType,
                  enableSearch: true
                },
                {
                  variableKey: 'operators',
                  label: 'Operator',
                  filterOptions: filterOptions?.operators,
                  enableSearch: true
                }
              ]}
            />
          </>
        }
        <Button
          id="create-event-button"
          className="create-event-button rounded"
          onClick={() => setCreateEventDialogVisible(true)}
        >
          Create Event
        </Button>
      </div>
      <CreateEventDialog
        onHide={() => setCreateEventDialogVisible(false)}
        visible={createEventDialogVisible}
        onSubmit={refetch}
      />
    </div>
  );
};

export default TopActionBar;
