import _ from 'lodash';

export * from './authentication';
export * from './fuseOptions';
export * from './time';

export const idFormatter = string => string.toLowerCase().split(' ').join('-');

export function createFilterList(data, keyName, defaultSelectedValue = true) {
  if (keyName) {
    return _.uniqBy(
      data.map(item => ({ name: item[keyName], id: item.id, selected: defaultSelectedValue })),
      'name'
    );
  } else {
    return _.uniqBy(
      data.map(item => ({ name: item, id: item, selected: defaultSelectedValue })),
      'name'
    );
  }
}

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

export const getStatusIndicatorStyle = (status) => {
  const statusColor = {
    LIVE: '#09BED2',
    NOT_LIVE: '#DADADA',
    PREPAIDS_ASSOCIATED: '#02E079',
    EXPECTED_NOT_RECEIVED: '#FF4E71',
    RECEIVED_NOT_ASSOCIATED: '#F7CA18',
    NO_PREPAIDS_EXPECTED: '#4950E5',
    UNKNOWN: '#BEBEBE'
  };

  return statusColor[status];
};

export const formatStatus = (status) => {
  const statusMessage = {
    PREPAIDS_ASSOCIATED: 'Associated passes',
    EXPECTED_NOT_RECEIVED: 'Expecting passes',
    RECEIVED_NOT_ASSOCIATED: 'Received passes, needs confirmation',
    NO_PREPAIDS_EXPECTED: 'No passes expected',
    UNKNOWN: 'Unknown'
  };

  return statusMessage[status];
};

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

/**
 * truncate string with max character count
 * @param {string} string to truncate
 * @param {number} max number of characters to allow
 * @return {string} truncated string
 */
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}
