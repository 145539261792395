import { Button, Dialog } from 'react-md';
import './SaveChoiceModal.scss';

const SaveChoiceDialog = ({
  visible,
  onClose,
  onSubmitSingle,
  onSubmitMultiple
}) => {

  const handleSingleChoice = () => {
    onClose();
    onSubmitSingle('single');
  };

  const handleMultipleChoice = () => {
    onClose();
    onSubmitMultiple('multiple');
  };

  return (
    <Dialog
      id="save-choice-modal"
      className="save-choice-modal"
      role="alertdialog"
      modal
      visible={visible}
      onRequestClose={onClose}
      aria-labelledby="dialog-title"
    >
      <h2 className="dialog-title">
        Save changes to:
      </h2>
      <div className="dialog-actions">
        <Button
          id="dialog-recurring-event-button"
          onClick={handleMultipleChoice}
          theme="clear"
          themeType="contained"
          className="tertiary"
        >
          All Recurring Events
        </Button>
        <Button
          id="dialog-single-event-button"
          onClick={handleSingleChoice}
          theme="clear"
          themeType="contained"
          className="tertiary"
        >
          Only Current Event
        </Button>
      </div>
    </Dialog>
  );
};

export default SaveChoiceDialog;
