import { FontIcon } from '@parkhub/parkhub-ui';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FilterGroupListItem = ({
  action = null,
  label = '',
  active = false,
  disabled = false
}) => (
  <li onClick={action} className={cx('filter-group-list-item', { 'filter-group-list-item-disabled': disabled && !active })}>
    <div className="filter-group-list-item-content">
      <div className="filter-group-list-item-icon-left">
        {active && <FontIcon className="check-icon">check</FontIcon>}
      </div>
      <div className="filter-group-list-item-label">
        {label}
      </div>
      <div className="filter-group-list-item-icon-right">
        <FontIcon className="navigate-icon">navigate_next</FontIcon>
      </div>
    </div>
  </li>
);

FilterGroupListItem.propTypes = {
  action: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default FilterGroupListItem;
