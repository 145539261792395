import { useRef, useState, useEffect, useMemo } from 'react';
import { isAfter, parse, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { getTimes, combineDateTime } from '../../utils';
import cx from 'classnames';
import Select from 'react-select';

const DATE_FORMAT = 'EEEE, LLL d';

const DateTimePicker = ({
  id,
  selectedDate = new Date(),
  selectedTime = '00:00',
  startDate = new Date(),
  onDateChange,
  onTimeChange,
  dateDisabled,
  timeDisabled,
  restrictToDateTime,
  label,
  timezone
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const popperRef = useRef(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const handleClick = (e) => {
    if (popperRef.current.contains(e.target)) {
      return;
    }

    setIsPopperOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
  };

  const handleDaySelect = (value) => {
    onDateChange(value);
    onTimeChange('');
    setIsPopperOpen(false);
  };

  const handleTimeSelect = ({ value }) => {
    onTimeChange(value);
  };

  const createTimeOptions = useMemo(() => {
    if (startDate) {
      if (!restrictToDateTime || (selectedDate && isAfter(startOfDay(new Date(selectedDate)), startOfDay(new Date(startDate))))) {
        // return all interval options
        return getTimes(startOfDay(selectedDate || new Date()));

      } else {
        // return remaining interval option
        return getTimes(startDate);
      }
    }
  }, [selectedDate, startDate, restrictToDateTime]);

  const findTime = (value) => {
    if (value) {
      const time = parse(value, 'HH:mm', new Date());
      const twelveHour = format(time, 'h:mm a');

      return {
        label: twelveHour,
        value
      };
    }

    return null;
  };

  const selectStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000
    })
  };

  return (
    <div className={cx('date-time-picker', { 'with-label': label })}>
      {label &&
        <label className="date-time-picker-label">
          {label}
        </label>
      }
      <div ref={popperRef}>
        <input
          id={`${id}-date-input`}
          name={`${id}-date-input`}
          type="text"
          autoComplete="off"
          placeholder="Date"
          value={selectedDate ? format(selectedDate, DATE_FORMAT) : ''}
          onChange={handleInputChange}
          className={cx('date-time-picker-input', { 'date-time-picker-input-disabled': dateDisabled })}
          onClick={() => dateDisabled ? null : setIsPopperOpen(true)}
          disabled={dateDisabled}
        />
        {isPopperOpen && (
          <div
            {...popper.attributes.popper}
            tabIndex={-1}
            style={popper.styles.popper}
            className="popper-container"
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              id={`${id}-date-picker`}
              fromDate={startDate}
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selectedDate}
              selected={selectedDate}
              onSelect={handleDaySelect}
              required
            />
          </div>
        )}
      </div>
      <Select
        styles={selectStyles}
        isDisabled={timeDisabled || !selectedDate}
        id={`${id}-time-select`}
        name={`${id}-time-select`}
        className="time-select"
        classNamePrefix="select"
        placeholder="Time"
        menuPlacement="auto"
        maxMenuHeight={150}
        onChange={handleTimeSelect}
        options={createTimeOptions}
        value={findTime(selectedTime)}
      />
      {selectedDate && selectedTime && timezone &&
        <div className="date-time-range-picker-timezone">
          {format(combineDateTime(selectedDate, selectedTime), 'z', { timeZone: timezone })}
        </div>
      }
    </div>
  );
};

export default DateTimePicker;
