import { useEffect } from 'react';
import {
  Button,
  TextIconSpacing,
  Dialog,
  RoomFontIcon,
  EventFontIcon,
  TurnedInFontIcon,
  PublicFontIcon,
  LabelFontIcon,
  CloseFontIcon,
  TimeToLeaveFontIcon,
  TrendingUpFontIcon,
  EditSVGIcon,
  RefreshFontIcon,
  FontIcon
} from 'react-md';
import { CircleCheckIcon, HelpOutlineIcon, WarningCircleIcon } from '../../assets/react-icons';
import { formatTimeRange, isNotEmpty, getStatusIndicatorStyle, formatStatus } from '../../utils';
import { useCreateEventContext, useLayoutContext, SwitchInput } from '../';
import { useLazyQuery } from '@apollo/client';
import { EVENT_SERIES_QUERY } from '../../graphql/eventSeries.query';
import cx from 'classnames';
import _ from 'lodash';
import './EventDialog.scss';

const EventDialog = () => {
  // Check if event is recurring
  const [eventSeries, { loading: eventSeriesLoading, data: eventSeriesData }] = useLazyQuery(EVENT_SERIES_QUERY, {
    onError: (error) => {
      console.error('error', error);
    }
  });

  const { eventDialogVisible, setEventDialogVisible, setSelectedEventTile, selectedEventTile } = useLayoutContext();
  const { setCreateEventDialogVisible, setEventForm } = useCreateEventContext();

  useEffect(() => {
    if (eventDialogVisible) {
      eventSeries({ variables: { eventId: selectedEventTile.id, includePastEvents: true } });
    }

  }, [eventDialogVisible, selectedEventTile, eventSeries]);

  const onClose = () => {
    setEventDialogVisible(false);
    setSelectedEventTile(null);
  };

  const isRescheduled = ['RESCHEDULED_WITH_EVENT', 'RESCHEDULED_WITHOUT_EVENT'].includes(selectedEventTile?.rescheduleStatus);

  const handleEditStart = () => {
    setEventForm({
      ...selectedEventTile,
      isRecurring: isNotEmpty(eventSeriesData?.eventSeries),
      isRescheduled,
      ...(isNotEmpty(eventSeriesData?.eventSeries) && { eventSeries: eventSeriesData.eventSeries })
    });
    onClose();
    setCreateEventDialogVisible(true);
  };

  const getIconForStatus = (status) => {
    const statuses = {
      RECEIVED_NOT_ASSOCIATED: <WarningCircleIcon color="rgba(0,0,0, .6)" width={24} height={24} />,
      PREPAIDS_ASSOCIATED: <CircleCheckIcon color="rgba(0,0,0, .6)" width={24} height={24} />,
      EXPECTED_NOT_RECEIVED: <HelpOutlineIcon color="rgba(0,0,0, .6)" width={24} height={24} />
    };

    return statuses[status];
  };


  const event = selectedEventTile;
  const canEdit = selectedEventTile?.rescheduleStatus !== 'RESCHEDULED_WITH_EVENT';

  return (
    <Dialog
      id="event-details-dialog"
      className="event-details-dialog"
      visible={eventDialogVisible}
      onRequestClose={onClose}
      aria-describedby="event-details"
    >
      {event &&
        <div className="event-details-dialog-content">
          <Button
            buttonType="icon"
            className="close-button"
            onClick={onClose}
          >
            <CloseFontIcon />
          </Button>
          <div className="event-details">
            <div className="details-item">
              <div className="details-icon y-center">
                <TurnedInFontIcon style={{ color: getStatusIndicatorStyle(event?.status) }} />
              </div>
              <div className="event-name">
                {event?.name}
              </div>
            </div>
            <div className="details-list">
              <div className="details-item">
                <div className="details-icon">
                  <RoomFontIcon />
                </div>
                <div>
                  <div className="detail-name">{event?.landmark?.name}</div>
                  <div className="detail-description">Location</div>
                </div>
              </div>
              <div className="details-item">
                <div className="details-icon">
                  <PublicFontIcon />
                </div>
                <div>
                  <div className="detail-name">{event?.regionName}</div>
                  <div className="detail-description">Region</div>
                </div>
              </div>
              {event?.type && (
                <div className="details-item">
                  <div className="details-icon">
                    <LabelFontIcon />
                  </div>
                  <div>
                    <div className="detail-name">{event?.type}</div>
                    <div className="detail-description">Event type</div>
                  </div>
                </div>
              )}
              {event?.tagName && (
                <div className="details-item">
                  <div className="details-icon">
                    <TurnedInFontIcon />
                  </div>
                  <div>
                    <div className="detail-name">Pricing Template</div>
                    <span className="detail-tag">{event?.tagName}</span>
                  </div>
                </div>
              )}
              <div className={cx('details-item', { 'text-disabled': isRescheduled })}>
                <div className="details-icon">
                  <EventFontIcon />
                </div>
                <div>
                  <div className="detail-name">
                    <time
                      dateTime={event?.from}
                      title={formatTimeRange(event?.from, event?.to, event?.landmark?.timezoneId)}
                    >
                      {formatTimeRange(event?.from, event?.to, event?.landmark?.timezoneId)}
                    </time>
                  </div>
                  <div className="detail-description">
                    Event time
                  </div>
                </div>
              </div>
              <div className={cx('details-item', { 'text-disabled': isRescheduled })}>
                <div className="details-icon">
                  <TimeToLeaveFontIcon />
                </div>
                <div>
                  <div className="detail-name">
                    <time
                      dateTime={event?.parkingFrom}
                      title={formatTimeRange(event?.parkingFrom, event?.parkingTo, event?.landmark?.timezoneId)}
                    >
                      {formatTimeRange(event?.parkingFrom, event?.parkingTo, event?.landmark?.timezoneId)}
                    </time>
                  </div>
                  <div className="detail-description">
                    Parking time
                  </div>
                </div>
              </div>
              <div className="details-item">
                <div className="details-icon">
                  <span className="status-icon" style={{ backgroundColor: getStatusIndicatorStyle(event?.status) }} />
                </div>
                <div>
                  <div className="detail-name">{formatStatus(event?.status)}</div>
                  <div className="detail-description">Status</div>
                  {isNotEmpty(event?.integrations) && (
                    <div className="details-list sub-list">
                      {event?.integrations?.map((integration, index) => (
                        <div className="details-item" key={`${event?.id}-${index}-${event?.from}-${Math.random()}`}>
                          <span className="details-icon">{getIconForStatus(integration?.status)}</span>
                          <div className="detail-name integration-source">
                            {`${_.capitalize(integration?.sourceName)} - ${formatStatus(integration?.status)}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {isNotEmpty(eventSeriesData?.eventSeries) &&
                <div className="details-item">
                  <div className="details-icon">
                    <RefreshFontIcon />
                  </div>
                  <div>
                    <div className="detail-name">
                      {`${eventSeriesData.eventSeries.length} ${eventSeriesData.eventSeries.length === 1 ? 'event' : 'events'} in series`}
                    </div>
                    <div className="detail-description">Recurring event</div>
                  </div>
                </div>
              }
              <div className="form-field-container">
                <div className="details-icon">
                  <TrendingUpFontIcon />
                </div>
                <div className="form-field-label">Go Live</div>
                <div className="form-field-input">
                  <SwitchInput
                    disabled
                    id="go-live"
                    control={event?.live}
                  />
                </div>
              </div>
              <div className="form-field-container">
                <div className="details-icon">
                  <TimeToLeaveFontIcon />
                </div>
                <div className="form-field-label">Transient</div>
                <div className="form-field-input">
                  <SwitchInput
                    disabled
                    id="transient"
                    control={event?.transient}
                  />
                </div>
              </div>
            </div>
            {event?.rescheduledEvent &&
              <div className="details-item">
                <div className="details-icon">
                  <FontIcon>event_repeat</FontIcon>
                </div>
                <div>
                  <div className="detail-name">
                    Rescheduled Event
                  </div>
                  <div className="details-list sub-list">
                    <div className="details-item">
                      <div className="details-icon"><TurnedInFontIcon /></div>
                      <div className="detail-name">{event?.rescheduledEvent?.name}</div>
                    </div>
                    <div className="details-item">
                      <div className="details-icon">
                        <EventFontIcon />
                      </div>
                      <div>
                        <div className="detail-name">
                          <time
                            dateTime={event?.rescheduledEvent?.from}
                            title={formatTimeRange(event?.rescheduledEvent?.from, event?.rescheduledEvent?.to, event?.landmark?.timezoneId)}
                          >
                            {formatTimeRange(event?.rescheduledEvent?.from, event?.rescheduledEvent?.to, event?.landmark?.timezoneId)}
                          </time>
                        </div>
                        <div className="detail-description">
                          Rescheduled Event time
                        </div>
                      </div>
                    </div>
                    <div className="details-item">
                      <div className="details-icon">
                        <TimeToLeaveFontIcon />
                      </div>
                      <div>
                        <div className="detail-name">
                          <time
                            dateTime={event?.rescheduledEvent?.parkingFrom}
                            title={formatTimeRange(event?.rescheduledEvent?.parkingFrom, event?.rescheduledEvent?.parkingTo, event?.landmark?.timezoneId)}
                          >
                            {formatTimeRange(event?.rescheduledEvent?.parkingFrom, event?.rescheduledEvent?.parkingTo, event?.landmark?.timezoneId)}
                          </time>
                        </div>
                        <div className="detail-description">
                          Rescheduled Parking time
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {event.rescheduleStatus === 'RESCHEDULED_WITHOUT_EVENT' &&
              <div className="details-item">
                <div className="details-icon">
                  <FontIcon>event_repeat</FontIcon>
                </div>
                <div>
                  <div className="detail-name">
                    {_.capitalize(_.lowerCase(event?.rescheduleStatus))}
                  </div>
                  <div className="detail-description">
                    Reschedule Status
                  </div>
                </div>
              </div>
            }
            {canEdit &&
              <div className="action-container">
                <Button
                  theme="primary"
                  themeType="contained"
                  className="action-button"
                  onClick={handleEditStart}
                  disabled={eventSeriesLoading}
                >
                  <TextIconSpacing icon={<EditSVGIcon />}>Edit Event</TextIconSpacing>
                </Button>
              </div>
            }
          </div>
        </div>
      }
    </Dialog>
  );
};

export default EventDialog;
