import { useMemo } from 'react';
import { Button } from '@parkhub/parkhub-ui';
import { useFilterGroupContext } from './FilterGroupContext';
import FilterSelection from './FilterSelection';
import { isNotEmpty } from '../../utils';

const FilterGroupNext = () => {
  const {
    activeFilters,
    navigateBack,
    filterToDisplay,
    updateActiveFilters
  } = useFilterGroupContext();
  const { label, filterOptions, variableKey, formatter, enableSearch } = filterToDisplay;
  const activeFilter = activeFilters?.[variableKey];

  const filterOptionsWithSelected = useMemo(() => {
    if (!isNotEmpty(filterOptions) && isNotEmpty(activeFilter)) {
      return activeFilter.map((option) => {
        return {
          id: option,
          name: option,
          selected: true
        };
      });
    }

    return filterOptions.filter(item => isNotEmpty(item)).map((option) => {
      const isActive = activeFilter?.includes(option);

      return {
        id: option,
        name: option,
        selected: isActive || false
      };
    });
  }, [activeFilter, filterOptions]);

  const onApply = (...args) => {
    updateActiveFilters(...args);
    navigateBack();
  };

  return (
    <>
      <div className="filter-group-item-header">
        <Button
          className="filter-group-header-button"
          onClick={() => navigateBack()}
          aria-label="go back"
          icon="keyboard_arrow_left"
        />
        <span className="chip-label">{label}</span>
      </div>
      <div className="filter-group-content">
        <FilterSelection
          activeFilters={activeFilters?.variableKey}
          onApply={onApply}
          variableKey={variableKey}
          label={label}
          labelKey="name"
          formatter={formatter}
          enableSearch={enableSearch}
          data={filterOptionsWithSelected}
        />
      </div>
    </>
  );
};

export default FilterGroupNext;
