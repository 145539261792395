import { useState, useEffect } from 'react';
import { Dialog, Button, Checkbox } from 'react-md';
import { useCreateEventContext } from '../';
import { format } from 'date-fns';
import { isNotEmpty } from '../../utils';
import _ from 'lodash';
import './RecurringPreviewDialog.scss';

const RecurringPreviewDialog = ({
  eventSeriesControls,
  onClose,
  visible,
  onSubmitCreate,
  onSubmitUpdate
}) => {
  const { previewEvents, setPreviewEvents, selectedEvent } = useCreateEventContext();
  const [selectableEventsControlList, setSelectableEventsControlList] = useState([]);

  useEffect(() => {
    if (visible && isNotEmpty(eventSeriesControls)) {
      setSelectableEventsControlList(eventSeriesControls);
    }

    return () => setSelectableEventsControlList([]);
  }, [eventSeriesControls, visible]);

  const isSelectable = isNotEmpty(selectableEventsControlList);

  const handleOnChangeCheckbox = (v, item) => {
    const index = _.findIndex(selectableEventsControlList, obj => obj.id === item.id);
    const newList = [...selectableEventsControlList];
    newList[index].selected = v;

    setSelectableEventsControlList(newList);
  };

  const handleHide = () => {
    setPreviewEvents([]);
    setSelectableEventsControlList([]);
    onClose();
  };

  const handleSubmit = () => {
    if (isSelectable) {
      const selected = _.map(_.filter(selectableEventsControlList, { selected: true }), (item) => item.id);
      onSubmitUpdate(selected);

    } else {
      onSubmitCreate();
    }

    handleHide();
  };

  return (
    <Dialog
      id="recurring-preview-dialog"
      className="recurring-preview-dialog"
      visible={visible}
      onRequestClose={handleHide}
      aria-describedby="create-recurring-event-preview"
      role="alertdialog"
      modal
    >
      <h2 className="dialog-title">
        {isSelectable ?
          'Confirm'
          : `Confirm Creation of ${previewEvents.length} Events`
        }
      </h2>
      <div className="recurring-preview-dialog-content">
        {isSelectable &&
          <p>{`The following events will be ${selectedEvent ? 'updated' : 'created'}. Deselect which ones you don’t wish to ${selectedEvent ? 'update' : 'create'}.`}</p>
        }
        <ul className="recurring-event-list">
          {isSelectable ? selectableEventsControlList.map((item, index) => (
            <li key={index} className="recurring-event-list-item">
              <Checkbox
                id={`preview-check-input-${index}`}
                className="ph-checkbox"
                name={`preview-check-input-${index}`}
                label={format(new Date(item.name), 'EEEE LLL d, yyyy')}
                title={format(new Date(item.name), 'EEEE LLL d, yyyy')}
                onChange={(e) => handleOnChangeCheckbox(e.currentTarget.checked, item)}
                checked={item.selected}
              />
            </li>
          )) : (
            isNotEmpty(previewEvents) && previewEvents.map((event, index) => (
              <li key={index}>
                {format(new Date(event?.from), 'EEEE LLL d, yyyy')}
              </li>
            )))}
        </ul>
      </div>
      <div className="recurring-preview-dialog-actions">
        <Button
          theme="clear"
          themeType="contained"
          className="full tertiary"
          onClick={handleHide}
        >
          Back to editor
        </Button>
        <Button
          theme="clear"
          themeType="contained"
          className="full tertiary"
          onClick={handleSubmit}
        >
          {selectedEvent ? 'Update' : 'Create'} Events
        </Button>
      </div>
    </Dialog>
  );
};

export default RecurringPreviewDialog;
