export const FUSE_OPTIONS = {
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 1000,
  maxPatternLength: 42,
  minMatchCharLength: 1,
  keys: [
    'name',
    'landmark.name'
  ]
};
