import { useState } from 'react';
import { List, ListItemLink, ResizeListener, ListFontIcon, EventFontIcon } from 'react-md';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import cx from 'classnames';
import './SideMenu.scss';

const SideMenu = ({ activeRoute }) => {
  const [collapseMenu, setCollapseMenu] = useState(false);

  function handleResize(event) {
    const width = event.currentTarget.innerWidth;
    setCollapseMenu(width <= 1220);
  };

  return (
    <>
      <aside className={`sidebar ${collapseMenu ? 'collapsed' : ''}`}>
        {collapseMenu ?
          <div className="side-menu-small">
            <List>
              <ListItemLink
                leftAddon={<EventFontIcon />}
                className={cx({ active: activeRoute === `${routes.WEEK}` })}
                component={Link}
                to={`${routes.WEEK}`}
              />
              <ListItemLink
                leftAddon={<ListFontIcon />}
                className={cx({ active: activeRoute === `${routes.AGENDA}` })}
                component={Link}
                to={`${routes.AGENDA}`}
              />
            </List>
          </div>
          :
          <div className="side-menu">
            <List>
              <ListItemLink
                className={cx({ active: activeRoute === `${routes.WEEK}` })}
                component={Link}
                to={`${routes.WEEK}`}
              >
                Week View
              </ListItemLink>
              <ListItemLink
                className={cx({ active: activeRoute === `${routes.AGENDA}` })}
                component={Link}
                to={`${routes.AGENDA}`}
              >
                Agenda View
              </ListItemLink>
            </List>
          </div>
        }
        <ResizeListener onResize={handleResize} />
      </aside>
    </>
  );
};

export default SideMenu;
