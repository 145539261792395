import PropTypes from 'prop-types';
import cx from 'classnames';
import { RefreshFontIcon, FontIcon } from 'react-md';
import { formatEventTime, getStatusIndicatorStyle } from '../../utils';
import { useLayoutContext } from '../';
import './EventTile.scss';

function getBackgroundColor(status) {
  const statusColor = {
    PREPAIDS_ASSOCIATED: '#D9FBEB',
    EXPECTED_NOT_RECEIVED: '#FFE5EA',
    RECEIVED_NOT_ASSOCIATED: '#FEF7DD',
    NO_PREPAIDS_EXPECTED: 'rgba(73, 80, 229, .2)',
    UNKNOWN: '#EEEEEE'
  };

  return statusColor[status];
}

function formatStatus(status) {
  const statusMessage = {
    PREPAIDS_ASSOCIATED: 'Associated passes',
    EXPECTED_NOT_RECEIVED: 'Expecting passes',
    RECEIVED_NOT_ASSOCIATED: 'Received passes, needs confirmation',
    NO_PREPAIDS_EXPECTED: 'No passes expected',
    UNKNOWN: 'Unknown'
  };

  return statusMessage[status];
}

const EventTile = ({ event }) => {
  const { setEventDialogVisible, setSelectedEventTile } = useLayoutContext();

  const handleSelect = () => {
    setSelectedEventTile(event);
    setEventDialogVisible(true);
  };

  const isRecurring = event?.slug.includes('recur');
  const isRescheduled = ['RESCHEDULED_WITH_EVENT', 'RESCHEDULED_WITHOUT_EVENT'].includes(event?.rescheduleStatus);

  return (
    <>
      <div
        onClick={handleSelect}
        className="event-tile"
      >
        <div className="event-tile-name">
          <span
            title={`Event Status: ${event?.live ? 'Live' : 'Not Live'}`}
            className="status-indicator"
            style={{ backgroundColor: getStatusIndicatorStyle(event?.live ? 'LIVE' : 'NOT_LIVE') }}
          />
          {event?.name}
        </div>
        <div className="event-tile-text">{event?.landmark.name}</div>
        <div className="event-tile-text">{event?.regionName}</div>
        <div className={cx('event-tile-description', { 'text-disabled': isRescheduled })}>Event Time</div>
        <div className={cx('event-tile-text', { 'text-disabled': isRescheduled })}>
          <time
            dateTime={event?.from}
            title={formatEventTime(event?.from)}
          >
            {formatEventTime(event?.from, event?.landmark?.timezoneId)}
          </time>
        </div>
        <div className={cx('event-tile-description', { 'text-disabled': isRescheduled })}>Parking Time</div>
        <div className={cx('event-tile-text', { 'text-disabled': isRescheduled })}>
          <time
            dateTime={event?.parkingFrom}
            title={formatEventTime(event?.parkingFrom)}
          >
            {formatEventTime(event?.parkingFrom, event?.landmark?.timezoneId)}
          </time>
        </div>
        <div
          className="event-status"
          style={{ backgroundColor: getBackgroundColor(event?.status, event?.to) }}
        >
          <div
            className="status-indicator"
            style={{ backgroundColor: getStatusIndicatorStyle(event?.status) }}
          />
          <div className="event-tile-text">
            {formatStatus(event?.status)}
          </div>
        </div>
        {isRecurring &&
          <div className="event-status ghost">
            <RefreshFontIcon />
            <div className="event-tile-text">
              Recurring
            </div>
          </div>
        }
        {isRescheduled &&
          <div className="event-status ghost">
            <FontIcon>event_repeat</FontIcon>
            <div className="event-tile-text">
              Rescheduled
            </div>
          </div>
        }
      </div>
    </>
  );
};

EventTile.propTypes = {
  event: PropTypes.object
};

export default EventTile;
