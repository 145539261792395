import { createContext, useContext, useState } from 'react';
import { format, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { stripDateFromString } from '../../utils';

const recurringEventOptions = [
  {
    label: 'Not recurring',
    value: 'not-recurring'
  },
  {
    label: 'Custom rule...',
    value: 'custom'
  }
];

const CreateEventContext = createContext([{}, () => { }]);

export const CreateEventContextProvider = ({ children }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [previewEvents, setPreviewEvents] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [createEventDialogVisible, setCreateEventDialogVisible] = useState(false);
  const [previewEventSeriesDialogVisible, setPreviewEventSeriesDialogVisible] = useState(false);
  const [saveChoiceModalVisible, setSaveChoiceModalVisible] = useState(false);
  const [goLiveControl, setGoLiveControl] = useState(true);
  const [recurringEventControl, setRecurringEventControl] = useState(recurringEventOptions[0]);
  const [transientControl, setTransientControl] = useState(false);
  const [landmarkControl, setLandmarkControl] = useState('');
  const [tagControl, setTagControl] = useState('');
  const [eventNameControl, setEventNameControl] = useState('');
  const [eventTypeControl, setEventTypeControl] = useState('');
  const [timezone, setTimezone] = useState('');
  const [eventFromDateControl, setEventFromDateControl] = useState('');
  const [eventToDateControl, setEventToDateControl] = useState('');
  const [eventFromTimeControl, setEventFromTimeControl] = useState('');
  const [eventToTimeControl, setEventToTimeControl] = useState('');
  const [parkingFromDateControl, setParkingFromDateControl] = useState('');
  const [parkingToDateControl, setParkingToDateControl] = useState('');
  const [parkingFromTimeControl, setParkingFromTimeControl] = useState('');
  const [parkingToTimeControl, setParkingToTimeControl] = useState('');
  const [countControl, setCountControl] = useState('');
  const [frequencyControl, setFrequencyControl] = useState('');
  const [byWeekDayControl, setByWeekDayControl] = useState([]);
  const [intervalControl, setIntervalControl] = useState('');
  const [untilControl, setUntilControl] = useState('');
  const [rescheduledControl, setRescheduledControl] = useState(false);
  const [rescheduledFromDateControl, setRescheduledFromDateControl] = useState('');
  const [rescheduledToDateControl, setRescheduledToDateControl] = useState('');
  const [rescheduledFromTimeControl, setRescheduledFromTimeControl] = useState('');
  const [rescheduledToTimeControl, setRescheduledToTimeControl] = useState('');
  const [rescheduledParkingFromDateControl, setRescheduledParkingFromDateControl] = useState('');
  const [rescheduledParkingToDateControl, setRescheduledParkingToDateControl] = useState('');
  const [rescheduledParkingFromTimeControl, setRescheduledParkingFromTimeControl] = useState('');
  const [rescheduledParkingToTimeControl, setRescheduledParkingToTimeControl] = useState('');
  const [rescheduledUnknownControl, setRescheduledUnknownControl] = useState(false);

  const clearRecurringForm = () => {
    setCountControl('');
    setFrequencyControl('');
    setByWeekDayControl([]);
    setIntervalControl('');
    setUntilControl('');
    setPreviewEvents([]);
  };

  const clearRescheduledForm = () => {
    setRescheduledFromDateControl('');
    setRescheduledToDateControl('');
    setRescheduledFromTimeControl('');
    setRescheduledToTimeControl('');
    setRescheduledParkingFromDateControl('');
    setRescheduledParkingToDateControl('');
    setRescheduledParkingFromTimeControl('');
    setRescheduledParkingToTimeControl('');
    setRescheduledUnknownControl(false);
  };

  const clearForm = () => {
    setSelectedEvent(null);
    setFormChanged(false);
    setGoLiveControl(true);
    setRecurringEventControl(recurringEventOptions[0]);
    setTransientControl(false);
    setLandmarkControl('');
    setTagControl('');
    setEventNameControl('');
    setEventTypeControl('');
    setTimezone('');
    setEventFromDateControl('');
    setEventToDateControl('');
    setEventFromTimeControl('');
    setEventToTimeControl('');
    setParkingFromDateControl('');
    setParkingToDateControl('');
    setParkingFromTimeControl('');
    setParkingToTimeControl('');
    setRescheduledControl(false);
    clearRecurringForm();
    clearRescheduledForm();
  };

  const setEventForm = (event) => {
    if (event) {
      const timezoneId = event?.landmark?.timezoneId;

      setEventNameControl(stripDateFromString(event?.name));
      setLandmarkControl(event?.landmark);
      setTimezone(timezoneId);
      setEventTypeControl(event?.metatags[0]);
      setEventFromDateControl(startOfDay(utcToZonedTime(event?.from, timezoneId)));
      setEventFromTimeControl(format(utcToZonedTime(event?.from, timezoneId), 'HH:mm'));
      setEventToDateControl(startOfDay(utcToZonedTime(event?.to, timezoneId)));
      setEventToTimeControl(format(utcToZonedTime(event?.to, timezoneId), 'HH:mm'));
      setParkingFromDateControl(startOfDay(utcToZonedTime(event?.parkingFrom, timezoneId)));
      setParkingFromTimeControl(format(utcToZonedTime(event?.parkingFrom, timezoneId), 'HH:mm'));
      setParkingToDateControl(startOfDay(utcToZonedTime(event?.parkingTo, timezoneId)));
      setParkingToTimeControl(format(utcToZonedTime(event?.parkingTo, timezoneId), 'HH:mm'));
      setTagControl({ id: event?.tagId, name: event?.tagName });
      setGoLiveControl(event?.live);
      setRecurringEventControl(event?.recurring || recurringEventOptions[0]);
      setTransientControl(event?.transient);
      setSelectedEvent(event);
      setRescheduledControl(event?.isRescheduled);
      setRescheduledUnknownControl(event?.rescheduleStatus === 'RESCHEDULED_WITHOUT_EVENT');
      setFormChanged(false);

    } else {
      clearForm();
    }
  };

  const resetEventForm = () => {
    setEventForm(selectedEvent);
    setFormChanged(false);
  };

  return (
    <CreateEventContext.Provider
      value={{
        createEventDialogVisible,
        setCreateEventDialogVisible,
        previewEventSeriesDialogVisible,
        setPreviewEventSeriesDialogVisible,
        saveChoiceModalVisible,
        setSaveChoiceModalVisible,
        goLiveControl,
        setGoLiveControl,
        transientControl,
        setTransientControl,
        landmarkControl,
        setLandmarkControl,
        tagControl,
        setTagControl,
        eventNameControl,
        setEventNameControl,
        eventTypeControl,
        setEventTypeControl,
        timezone,
        setTimezone,
        eventFromDateControl,
        setEventFromDateControl,
        eventToDateControl,
        setEventToDateControl,
        eventFromTimeControl,
        setEventFromTimeControl,
        eventToTimeControl,
        setEventToTimeControl,
        parkingFromDateControl,
        setParkingFromDateControl,
        parkingToDateControl,
        setParkingToDateControl,
        parkingFromTimeControl,
        setParkingFromTimeControl,
        parkingToTimeControl,
        setParkingToTimeControl,
        setEventForm,
        selectedEvent,
        clearForm,
        formChanged,
        setFormChanged,
        recurringEventControl,
        setRecurringEventControl,
        recurringEventOptions,
        countControl,
        setCountControl,
        frequencyControl,
        setFrequencyControl,
        byWeekDayControl,
        setByWeekDayControl,
        intervalControl,
        setIntervalControl,
        untilControl,
        setUntilControl,
        rescheduledControl,
        setRescheduledControl,
        rescheduledFromDateControl,
        setRescheduledFromDateControl,
        rescheduledToDateControl,
        setRescheduledToDateControl,
        rescheduledFromTimeControl,
        setRescheduledFromTimeControl,
        rescheduledToTimeControl,
        setRescheduledToTimeControl,
        rescheduledParkingFromDateControl,
        setRescheduledParkingFromDateControl,
        rescheduledParkingToDateControl,
        setRescheduledParkingToDateControl,
        rescheduledParkingFromTimeControl,
        setRescheduledParkingFromTimeControl,
        rescheduledParkingToTimeControl,
        setRescheduledParkingToTimeControl,
        rescheduledUnknownControl,
        setRescheduledUnknownControl,
        clearRescheduledForm,
        clearRecurringForm,
        previewEvents,
        setPreviewEvents,
        resetEventForm
      }}
    >
      {children}
    </CreateEventContext.Provider>
  );
};

export const useCreateEventContext = () => useContext(CreateEventContext);
